import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import buttondownload from "../../assets/jss/material-kit-react/buttondownload.jsx";
import downloadFile from '../../assets/downloads/Colliery_Business_Park.pdf'


function DownloadButton() {
    return (
      <div>
         <a   className="downloadbutton" href={downloadFile} download>DOWNLOAD BROCHURE</a>
      </div>
    )
  }

  export default withStyles(buttondownload)(DownloadButton);